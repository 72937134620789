


























































import ModalImportPrivateKeyTs from './ModalImportPrivateKeyTs';
export default class ModalImportPrivateKey extends ModalImportPrivateKeyTs {}
