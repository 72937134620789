






// external dependencies
import HarvestingPageTs from './HarvestingPageTs';

export default class HarvestingPage extends HarvestingPageTs {}
